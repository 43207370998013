import {LocationFragment} from "@services/graphql/queries/location";
import moment, {Moment} from "moment-timezone";
import {I18n} from "next-translate";
import {useTranslation} from "ni18n";
import {useMemo} from "react";
import {RootStateLocation, RootStateTimeBlock} from "src/store/types";

import {isCI, laTimezone} from "../components/_common/_constants";
import {MsMap} from "../constants/MsMap";
import {SpecialtyId} from "../constants/specialtyIds";
import {isTestEnv} from "./isTestEnv";
import {maybeGetLocationWithSpecialtyHours} from "./maybeGetLocationWithSpecialtyHours";

const twentyFourHrtoAmPm = (time: string): string => {
  const chars = Array.from(time);
  const indexOfColon = chars.indexOf(":");
  const twentyFourHour = parseInt(chars.splice(0, indexOfColon).join(""));
  const minutes = chars.splice(indexOfColon - 1).join("");
  const showMinutes = minutes !== "00";
  const isPM = twentyFourHour > 12;
  const hour = twentyFourHour - (isPM ? 12 : 0);
  return `${hour}${showMinutes ? `:${minutes}` : ""}${isPM ? "pm" : "am"}`;
};

export const isInRange = (num: number, start: number, end: number) => {
  return start <= num && num <= end;
};

export const isInTimeRange = (startTime = Date.now(), endTime = startTime + MsMap.ONE_HOUR) => {
  const now = Date.now();
  return isInRange(now, startTime, endTime);
};

export type GetOpenTimeResult = {
  timeString: string;
  isOpenNow?: boolean;
  timeBlock?: RootStateTimeBlock;
  daysFromToday?: number;
  isBeforeOpeningToday?: boolean;
};

export const getOpenTime = (
  i18n: I18n,
  location: RootStateLocation,
  specialtyId?: SpecialtyId,
): GetOpenTimeResult => {
  const hours = (specialtyId && location?.specialties?.[specialtyId]?.hours) || location?.hours;

  const closedReturnValue = {
    timeString: i18n.t("Hours unknown", {ns: "website-db"}),
  };
  if (!hours) return closedReturnValue;

  const now = moment().tz(location.timezone || laTimezone);
  const _time = now.format("HH:mm");

  const soonestOpenDay = Array(60)
    .fill(null)
    // @ts-expect-error TS2345: Argument of type '(acc: { day: Moment; timeBlock: RootStateTimeBlock; diff: number; }, _next: any, i: number) => false | { day: Moment; timeBlock: RootStateTimeBlock; diff: number; } | undefined' is not assignable to parameter of type '(previousValue: { day: Moment; timeBlock: RootStateTimeBlock; diff: number; }, currentValue: any, currentIndex: number, array: any[]) => { day: Moment; timeBlock: RootStateTimeBlock; diff: number; }'.
    .reduce<{day: Moment; timeBlock: RootStateTimeBlock; diff: number}>((acc, _next, i) => {
      if (acc) {
        return acc;
      }
      const _day = now.add(i, "days");
      const timeBlock = hours.find(block => block.day.toString() === _day.format("e"));
      const isAfterClosingToday = i === 0 && timeBlock && _time >= timeBlock.to;

      return (
        timeBlock &&
        !isAfterClosingToday && {
          day: _day,
          timeBlock,
          diff: i,
        }
      );
    }, null);

  if (!soonestOpenDay || !soonestOpenDay.timeBlock?.to || (isCI && !isTestEnv))
    return closedReturnValue;

  const {
    timeBlock,
    timeBlock: {to, from},
    diff,
    day,
  } = soonestOpenDay;
  const isOpenToday = diff === 0;
  const isBeforeOpeningToday = _time < from && isOpenToday;
  const isOpenNow = isOpenToday && !isBeforeOpeningToday;

  const time = twentyFourHrtoAmPm(isBeforeOpeningToday ? from : isOpenToday ? to : from);
  const timeString = isBeforeOpeningToday
    ? i18n.t("Opens {{time}} today", {ns: "website-db", time})
    : isOpenNow
    ? i18n.t("until {{time}}", {ns: "website-db", time})
    : diff === 1
    ? i18n.t("Opens {{time}} tomorrow", {ns: "website-db", time})
    : diff < 7
    ? i18n.t("Opens {{time}} {{dayOfWeek}}", {ns: "website-db", time, dayOfWeek: day.format("ddd")})
    : i18n.t("Opens {{time}} {{date}}", {ns: "website-db", time, date: day.format("MMM D")});

  return {
    timeString,
    isOpenNow,
    isBeforeOpeningToday,
    timeBlock,
    daysFromToday: diff,
  };
};

export const useOpenTime = (
  location: LocationFragment,
  specialtyId?: SpecialtyId,
): GetOpenTimeResult => {
  const i18n = useTranslation("website-db");

  return useMemo(() => {
    const locationWithSpecialtyHours = maybeGetLocationWithSpecialtyHours(
      location,
      specialtyId,
    ) as unknown as RootStateLocation;
    return getOpenTime(i18n, locationWithSpecialtyHours, specialtyId);
  }, [i18n, location, specialtyId]);
};

const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const checkIfSameDay = (time1: number, time2: number, timezone = userTimezone) =>
  moment(time1).tz(timezone).isSame(time2, "day");

// ref: https://stackoverflow.com/a/36041365/263050
export const getEndOfDay = (millis: number, timezone: string): number => {
  const m = moment(millis);
  return +m.clone().tz(timezone).endOf("day").utc();
};

export const getStartOfDay = (millis: number, timezone: string): number => {
  const m = moment(millis);
  return +m.clone().tz(timezone).startOf("day").utc();
};
